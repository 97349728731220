<template>
    <div>
        <v-progress-linear :indeterminate="loading" color="primary"></v-progress-linear>

        <v-container class="align-self-end py-7 px-5">
            <v-row>
                <v-col cols="12">
                    <v-btn class="mb-2" text color="dark" small @click="back()"
                        ><v-icon small class="mr-2">mdi-arrow-left</v-icon>Back</v-btn
                    >

                    <div class="d-flex align-center justify-space-between flex-wrap">
                        <h1 class="dark--text mb-3">{{ artist }}</h1>
                        <v-chip class="mb-3" color="pink" label text-color="white" v-if="category && category.length">
                            <v-icon left> mdi-label </v-icon>
                            {{ category }}
                        </v-chip>
                    </div>

                    <p class="ma-0 mt-3" v-if="body && body.length">{{ body }}</p>
                </v-col>
            </v-row>
        </v-container>

        <v-container class="py-7 px-4">
            <v-row>
                <v-col cols="12">
                    <template v-if="video">
                        <app-video :item="video"></app-video>
                    </template>

                    <template v-else-if="entry.data.videoSourceFull">
                        <v-select
                            v-model="selectedVideo"
                            :items="videos"
                            align-center
                            justify-center
                            class="custom-select"
                            item-text="label"
                        ></v-select>
                        <iframe
                            class="light full-width full-height cursor-pointer"
                            v-if="videoSource"
                            width="100%"
                            height="800"
                            :src="videoSource"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                        ></iframe>
                    </template>

                    <template v-else>
                        <app-image :src="image" contain></app-image>
                    </template>
                </v-col>

                <v-col cols="12">
                    <div class="awards">
                        <div class="mr-2" v-for="(award, index) in entryAwards" :key="index">
                            <img v-if="award" :src="$fluro.asset.imageUrl(award)" width="100" height="100" />
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import Entry from "@/modules/fluro/entry";
import Competition from "@/modules/fluro/competition";

import AppVideo from "@/components/app-video.vue";
import AppImage from "@/components/app-image.vue";

export default {
    name: "entry-id",

    meta() {
        return {
            title: this.artist || "Loading...",
        };
    },

    data() {
        return {
            loading: false,
            entry: {},
            competition: {},
            videos: [{ label: "Short Video" }, { label: "Full Video" }],
            selectedVideo: "Short Video", // default selection
        };
    },

    components: {
        AppVideo,
        AppImage,
    },

    computed: {
        videoSource() {
            return this.selectedVideo == "Short Video"
                ? this.entry.data.videoSourceShort
                : this.entry.data.videoSourceFull;
        },
        video() {
            if (this.entry?.data?.video?._id) {
                return this.entry?.data?.video;
            }
            return null;
        },
        image() {
            return this.$fluro.asset.imageUrl(this.entry?.data?.image?._id || this.entry?.data?.image, 750, null);
        },
        artist() {
            return this.entry?.data?.managedAuthor?.title || this.entry?.managedAuthor?.title || "";
        },
        body() {
            return this.entry?.data?.body || "";
        },
        category() {
            return this.entry?.data?.category?.title || "";
        },
        compAwards() {
            return this.competition?.data?.awardsOld || [];
        },
        entryAwards() {
            let tags = this.entry?.tags;
            let awards = this.entry?.data?.awards || [];

            //Return here for new style
            if (awards.length) return awards;

            //Continue here for old style
            this.compAwards.forEach((award) => {
                let match = tags.find((tag) => (tag._id || tag) == (award.tag?._id || award.tag));

                if (match) {
                    awards.push(award.image);
                }
            });
            return awards;
        },
    },

    methods: {
        async init() {
            this.loading = true;

            this.entry = await Entry.get(this.$route.params.id).then(({ data }) => data);
            console.log("ENTRY", this.entry);

            this.competition = await Competition.get(
                this.entry?.data?.competition?._id || this.entry?.data?.competition
            ).then(({ data }) => data);
            console.log("COMPETITION", this.competition);

            this.loading = false;
        },
        back() {
            this.$router.push({ name: "competition-id", params: { id: this.competition._id } });
        },
    },

    async mounted() {
        await this.init();
    },
};
</script>

<style lang="scss" scoped>
.awards {
    display: flex;
    align-items: center;
    transform: translate(-5px, -5px);

    img {
        -webkit-filter: drop-shadow(5px 5px 5px #333);
        filter: drop-shadow(5px 5px 5px #333);
    }
}
</style>
